<template>
  <WeContainer v-if="ready">
    <div class="media align-items-center">
      <img
        v-if="marketplace.detail.logo"
        v-bind:src="marketplace.detail.logo"
        width="100"
      />
      <i v-else class="fas fa-shopping-cart"></i>
      <div class="media-body">
        <h5 class="mb-0">
          <span class="ml-2"
            >{{ marketplace.detail.title | capitalizeFirstLetter }} Entegrasyon
            Ayarları</span
          >
        </h5>
      </div>
    </div>
    <hr />

    <!-- Form Content -->
    <div class="row">
      <div
        v-for="element in marketplace.detail.elements"
        v-bind:key="element.name"
        v-bind:class="element.class"
      >
        <div v-if="element.name == 'appKey_cron_1'">
          <h5>Yedek Kullanıcı Bilgileri</h5>
          <hr style="border: 1px solid #ad2929" />
        </div>
        <div v-if="element.name == 'appSecret_cron_1'">
          <h5>&nbsp;</h5>
          <hr style="border: 1px solid #ad2929" />
        </div>
        <WeInput
          v-if="element.form != 'select'"
          v-model="element.value"
          v-bind:required="element.required"
          v-bind:type="element.type"
          v-bind:label="element.title"
          v-bind:name="element.name"
          autocomplete="off"
        />
        <div v-else>
          <label class="custom-label">{{ element.title }}</label>
          <WeRowInput
            form="vSelect"
            v-bind:clearable="false"
            v-bind:name="element.name"
            class="mb-4"
            label-class="d-none"
            inputClass="col-12 col-lg-12"
            v-bind:close-on-select="true"
            v-bind:option-prop="element.options"
            v-model="element.value"
          />
        </div>
      </div>
    </div>
    <!-- ./Form Content -->

    <!-- Submit -->
    <div class="text-center">
      <span
        class="btn btn-primary"
        v-if="!submit"
        v-on:click="updateMarketplace"
        ><i class="fas fa-save"></i> Kaydet</span
      >
      <span v-else class="btn btn-primary"
        ><i class="fas fa-spinner fa-spin"></i
      ></span>
    </div>
    <!-- ./Submit -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      _marketplace: null,
    };
  },
  methods: {
    ...mapActions("marketplace", ["load", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    upMarketplace() {
      this.submit = true;
      this.update({
        onSuccess: (result) => {
          if (result && result.data && result.data.status == "success") {
            result.data.message = "Kayıt Başarılı";
          } else {
            result.data.message = "Kayıt yapılamadı";
          }
          this.showAlert(result, this.redirectToList);
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateMarketplace() {
      var currentExtraCost = this.marketplace.detail.elements.find(
        (A) => A.name == "extraCost"
      );
      var baseExtraCost = this._marketplace.elements.find(
        (A) => A.name == "extraCost"
      );
      if (
        currentExtraCost &&
        baseExtraCost &&
        currentExtraCost.value != baseExtraCost.value
      ) {
        this.$swal({
          title: "Uyarı",
          text:
            "İlave maliyat alanını değiştirdiniz. İşlemi onaylarsanız pazar yerine ait tüm ürünlerin ilave maliyeti " +
            currentExtraCost.value +
            " olarak güncellenecektir.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: "Onayla",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.upMarketplace();
          }
        });
      } else {
        this.upMarketplace();
      }
    },
    getMarketplaceById() {
      this.ready = false;
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            setTimeout(() => {
              this.ready = true;
              this._marketplace = JSON.parse(
                JSON.stringify(this.marketplace.detail)
              );
            }, 500);
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/marketplaces");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["marketplace", "shared"]),
  },
  mounted() {
    this.load();
    this.getMarketplaceById();
  },
};
</script>
